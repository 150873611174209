import React from "react";
import ReactDOM from "react-dom";
import "regenerator-runtime/runtime";
import App from "./App";
// Get the script tag with the agent ID
var scriptTag = document.getElementById("Agent-Bubble-Widget");
var agentId = scriptTag === null || scriptTag === void 0 ? void 0 : scriptTag.getAttribute("agent-id");
var lang = scriptTag === null || scriptTag === void 0 ? void 0 : scriptTag.getAttribute("lang");
if (!lang) {
    lang = "fr";
}
// Check for the script tag and agent ID
if (!scriptTag || !agentId || !lang) {
    throw new Error("No script tag with agent ID found or agent ID is missing");
}
ReactDOM.render(React.createElement(App, { agentId: agentId, lang: lang }), document.getElementById("genial-agent"));
