(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ReactDOM"), require("PropTypes"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "ReactDOM", "PropTypes"], factory);
	else if(typeof exports === 'object')
		exports["GenialChatBubble"] = factory(require("React"), require("ReactDOM"), require("PropTypes"));
	else
		root["GenialChatBubble"] = factory(root["React"], root["ReactDOM"], root["PropTypes"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__4883__, __WEBPACK_EXTERNAL_MODULE__1845__, __WEBPACK_EXTERNAL_MODULE__6262__) => {
return 